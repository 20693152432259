/* eslint-disable consistent-return */
import React from 'react';
import { MSG_CONTENT_TYPES } from '../../../models';
import { paintBody, paintHeader } from '../template-message-bubble/utils';


export const getLabelInteractiveList = (linkedMessage, payloadMessage) => {
  let labelToReturn;
  if (linkedMessage?.contentType === MSG_CONTENT_TYPES.INTERACTIVE) {
    if (linkedMessage?.content?.interactive?.type === 'list') {
      const sections = linkedMessage?.content?.interactive?.action?.sections;
      let title;
      sections?.forEach(section => {
        section.rows?.forEach(row => {
          if (row.payload === payloadMessage) {
            title = row.title;
            labelToReturn = title;
          }
        });
      });
    }
  }
  return labelToReturn;
}
export const getLabelInteractiveButton = (linkedMessage, payloadMessage) => {
  let labelToReturn;
  const buttons = linkedMessage?.content?.interactive?.action?.buttons;
  let title;
  buttons?.forEach((button) => {
    if (button.reply?.payload === payloadMessage) {
      title = button.reply.title;
      labelToReturn = title;
    }
  });
  return labelToReturn;
}
// parseo infumable para poder conseguir el label a partir del eventName que viene en el mensaje quickReply para template.
export const getLabelTemplate = (linkedMessage, payloadMessage) => {
  // subObject components in Template
  const buttonsComponents = linkedMessage?.content?.components?.filter((el) => (el?.type === 'button'));
  // subObject templateComponents in Template
  const buttonsTemplateComponents = linkedMessage?.content?.templateComponents?.find(el => el?.type === 'BUTTONS');

  // find button that has payload equals to eventName from quickReply message
  const theButton = buttonsComponents?.length && buttonsComponents?.find((el) => 
    el?.parameters?.find(param => param?.payload === payloadMessage)
  );
  // get index of button from subObject templateComponents in Template
  const indexButtonToFindLabel = theButton?.index && parseInt(theButton.index, 10);
  // find label using the index of the button in templateComponents;
  return  indexButtonToFindLabel !== null && indexButtonToFindLabel !== undefined
    && buttonsTemplateComponents?.buttons?.length
    && buttonsTemplateComponents?.buttons[indexButtonToFindLabel]?.text;
};

export const getJsxLinkedMessage = (linkedMessage) => {
  const isLinkedTemplateMessage = linkedMessage?.contentType === MSG_CONTENT_TYPES.TEMPLATE;
  const isLinkedEventFlowMessage = linkedMessage?.contentType === MSG_CONTENT_TYPES.EVENT;
  const isLinkedInteractiveMessage = linkedMessage?.contentType === MSG_CONTENT_TYPES.INTERACTIVE;
  if (isLinkedTemplateMessage) {
    const jsxToReturn = (
      <>
      {paintHeader(linkedMessage.content)}
      {paintBody(linkedMessage.content)}
      </>
    );
    return jsxToReturn;

  }
  if (isLinkedEventFlowMessage) {
    return ( 
      null
    )
  }
  if (isLinkedInteractiveMessage) {
    const interactive = linkedMessage?.content?.interactive;
    const jsxToReturn = (
    <>
    {interactive?.header?.text ? <div className="interactive-header-wrapper">
      <p className="interactive-header-text">{interactive?.header?.text}</p>
    </div> : <div/>}
    {interactive?.body?.text ? <div className="interactive-body-wrapper">
      <p className="interactive-body-text">{interactive?.body?.text}</p>
    </div> : <div/>}
    </>)
    return jsxToReturn;
  }
  return null;
} 