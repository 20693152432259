import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import ReactTooltip from 'react-tooltip';
import { TemplateMessageBubbleStyled } from './styled';
import i18n from '../../../assets/i18n';
import { paintBody, paintHeader, paintFooter } from './utils';
import { InteractiveExtraContent } from './InteractiveExtraContent';


const getTemplateComponentFromContent = (content, elementName) => {
  return content.templateComponents.find(el => el?.type?.toLowerCase() === elementName?.toLowerCase())
}
const getVariablesFromContent = (content, elementName) => {
  return content.components.find(el => el?.type === elementName);
}

const TemplateMessageBubble = ({ colorClass, date, sendType, statusIcon, errorIcon, content, onMultimediaClick }) => {

  const paintTemplate = (_content) => {
    const headerPart = paintHeader(_content, onMultimediaClick);
    const bodyPart = paintBody(_content);
    const footerPart = paintFooter(_content);
    const jsxToReturn = (
      <div className="container-template">
        {headerPart}
        {bodyPart}
        {footerPart}
      </div>
    );
    return jsxToReturn;
  };
  let templateJsx = <p className="deleted-text">{`${i18n.chats.template} ${content.name} ${i18n.chats.sent}`}</p>
  let buttonsJsx;
  let variablesButtons;
  if (content?.templateComponents?.length) {
    templateJsx = paintTemplate(content);
    buttonsJsx = getTemplateComponentFromContent(content, 'BUTTONS');
    variablesButtons = getVariablesFromContent(content, 'buttons');
  }

  return (
    <div className="prueba">
      <TemplateMessageBubbleStyled
        sendType={sendType}
        interactiveButtons={buttonsJsx}
        isThereErrorIcon={!_.isNil(errorIcon)}
      >
        {errorIcon && (
          <div className="error-icon-holder" data-tip={i18n.errors.tooltipError} data-offset="{'left': 5}">
            {errorIcon}
          </div>
        )}
        <div className={`${colorClass} template-container`}>
          <div className="img-text-holder">
            <div className="message-caption">{templateJsx}</div>
            <div className="info-row">
              <p className="timespan-message">{date}</p>
              {statusIcon}
            </div>
          </div>
        </div>
        <ReactTooltip type="info" />
        {buttonsJsx &&
          <InteractiveExtraContent
            buttonsContent={buttonsJsx}
            buttonsVariables={variablesButtons}
          />
        }
      </TemplateMessageBubbleStyled>
    </div>
  );
}

TemplateMessageBubble.propTypes = {
  colorClass: PropTypes.string,
  date: PropTypes.string,
  sendType: PropTypes.string,
  statusIcon: PropTypes.any,
  errorIcon: PropTypes.any,
  content: PropTypes.object,
  onMultimediaClick: PropTypes.func,
};

export default TemplateMessageBubble;
